import * as React from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { Label } from 'styleguide/components/forms';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import RadioTabs from 'styleguide/components/Formik/RadioTabs/index';
import UserContext from 'contexts/UserContextContainer/UserContext';
import Button from 'styleguide/components/Button/Button';
import AddressBook from 'app/styleguide/components/Formik/AddressBook/AddressBook';
import ValidateAddressContext from 'contexts/ValidateAddressContext/ValidateAddressContext';
import OrderContext from 'contexts/OrderContext/OrderContext';
import FormikFieldWrapper from 'app/styleguide/components/Formik/FormikFieldWrapper/FormikFieldWrapper';

interface Props {
  addresses: Address[];
  newAddress: boolean;
  setNewAddress: (newAddress: boolean) => void;
}

const ShippingAddressStepEdit = ({ addresses, newAddress, setNewAddress }: Props) => {
  const formikProps = useFormikContext<FormikValues>();
  const { currentUser } = React.useContext(UserContext);
  const { order } = React.useContext(OrderContext);
  const validateAddressContext = React.useContext(ValidateAddressContext);

  return (
    <>
      <div>
        <div className="paragraph-bold-mobile mb-4">Contact Information</div>
        <FormikFieldWrapper
          required
          type="email"
          name="email"
          inPlaceError
          componentType="input"
          labelComponent="Email address"
          data-cy="subscribeMailEmailInput"
        />
        {currentUser && currentUser.klaviyoSubscribed ? (
          <div />
        ) : (
          <div className="flex items-center justify-start">
            <FormikFieldWrapper
              name="subscribeMail"
              componentType="checkbox"
              size="sm"
              data-cy="subscribeMailCheckbox"
              className="mt-1"
              labelComponent={
                <Label
                  placement="left"
                  normalFontWeight
                  className="!pl-2 !font-hvRoman !text-sm !leading-5 text-gray-500 -md:!pl-4"
                >
                  Sure, occasionally send me an email about Printivity tips and deals.
                </Label>
              }
            />
          </div>
        )}
      </div>
      {!!currentUser && !currentUser.smsEnabled && (
        <div className="!mt-8 flex flex-col">
          <div className="paragraph-bold-mobile">Mobile Notifications</div>
          <div className="caption mb-6 mt-1 text-gray-500">
            Avoid delaying your order by receiving text reminders to your mobile device for time-sensitive
            deadlines.
          </div>
          <div className="flex -md:flex-col">
            <FieldWrapper
              className="mr-8 w-full md:flex-[60%]"
              Input={
                <RadioTabs name="sendSmsNotifications">
                  <FormikFieldWrapper
                    name="sendSmsNotifications"
                    dataCy="sendSmsNotificationsRadioBtn.true"
                    value
                    labelComponent="Yes"
                    componentType="radio"
                    size="sm"
                  />
                  <FormikFieldWrapper
                    name="sendSmsNotifications"
                    dataCy="sendSmsNotificationsRadioBtn.false"
                    value={false}
                    labelComponent="No"
                    componentType="radio"
                    size="sm"
                  />
                </RadioTabs>
              }
            />
            <FormikFieldWrapper
              name="smsPhone"
              data-cy="smsPhoneInput"
              componentType="input"
              labelComponent="Mobile Number"
              secondaryMessage="Printivity does not use your phone number for marketing"
              wrapperClassName="-md:mt-4 -md:mb-2"
            />
          </div>
        </div>
      )}
      <div className="mt-9">
        <AddressBook
          addressType="ship"
          initialAddress={order.shippingAddress}
          addresses={addresses}
          newAddress={newAddress}
          setNewAddress={setNewAddress}
          titleOnTheLeft
          addNewAddressToBookCheckBox={!!currentUser}
          addressFormProps={{
            zipWarning: true,
            poBoxWarning: true,
            checkoutMode: true,
            className: '!mt-0 mb-5',
            international: true,
            validCountries: ['US', 'CA'],
          }}
        />
      </div>
      <Button
        className="w-full !px-0 !font-hvMedium !text-base"
        type="button"
        color="orange"
        onClick={() => {
          formikProps.validateForm().then(errors => {
            if (Object.keys(errors).length === 0) {
              if (formikProps.values.shipAddressAttributes.countryId === 214) {
                validateAddressContext.validateAddress(null, formikProps.values.shipAddressAttributes);
              } else {
                formikProps.submitForm();
              }
              return;
            }

            const fieldsWithValidationErrors = Object.keys(errors).reduce((acc, key) => {
              if (errors[key] && typeof errors[key] === 'object') {
                acc[key] ||= {};
                Object.keys(errors[key]).forEach(nestedKey => {
                  acc[key][nestedKey] = true;
                });
              } else {
                acc[key] = true;
              }
              return acc;
            }, {});

            formikProps.setTouched(fieldsWithValidationErrors);
          });
        }}
        data-cy="shipAddressStepBtn"
        disabled={!formikProps.values.shipAddressId && (!formikProps.dirty || !formikProps.isValid)}
      >
        Select Delivery
      </Button>
    </>
  );
};
export default ShippingAddressStepEdit;
